.Tabs {
    width:100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    border-radius: 1rem;
    animation: 1s fadeIn;
    overflow: auto;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

ul.nav {
    width: 100%;
    min-height: 185px;
    display: flex;
    margin: auto;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    border-radius:1rem;
    z-index: 200;
    overflow:auto;
    gap: 0;

}
.tab-nav-item-wrapper{
    display: flex;
    flex-direction: row;
    gap:0;
    align-items: center;
    justify-content: center;
}

.horizontal-separator{
    width: 1.5vw;
    height: 2rem;
    background-color: var(--primary-shade-2-opacity);
    border-top: var(--dark-text-color) .2rem solid;
    border-bottom: var(--dark-text-color) .2rem solid;
    border-radius: .2rem;
}

.horizontal-separator-none{
    display: none;
}

ul.nav li {
    background-color: var(--primary-shade-2-opacity2);
    color: var(--dark-text-color);
    padding: 2rem;
    list-style: none;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.7s;
    font-size: 1.5rem;
    direction: rtl;
    font-weight: bold;
    border: var(--dark-text-color) .2rem solid;
    border-radius: 50%;
    border-collapse: collapse;

}

ul.nav li:hover {
    color: var(--light-text-color);
    background: var(--primary-color);
    border: var(--primary-shade-2-opacity) .3rem solid;
}

ul.nav li.active {
    color: var(--light-text-color);
    background: var(--primary-color);
    border: var(--primary-shade-2-opacity) .3rem solid;
    padding: 4rem;
}

.TabContent {
    font-size: 1rem;
    text-align: center;
    direction: ltr;
}

@keyframes listOverflowArrow {
 0%{
    transform: translateX(0px);
  }
  25%{
    transform: translateX(-50px);
  }
  50%{
    transform: translateX(0px);
  }
}

@media screen and (max-width: 1024px) {
    ul.nav{
        gap: 0;
        min-height: 130px;
        align-items: center;
        justify-content: stretch;
    }
}

@media screen and (max-width: 768px) {
    .Tabs {
        width:100%;
    }

    .hand-pointer{
        position: relative;
        top:-5vh;
        right:-20%;
        animation: listOverflowArrow 3s ease-in-out infinite;
    }

    ul.nav li {
        font-size: 1.3rem;
        padding: 1rem;
    }

    ul.nav li.active {
        padding: 2rem;
    }

    .horizontal-separator{
        height: 1rem;
        width: .5rem;
    }

    .tab-nav-item-wrapper{
        width: auto;
    }
}