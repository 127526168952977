.progress-wrapper{
    position: relative;
    text-align: center;
    margin: auto;
    width: 388px;
    height: 440px;
    z-index: 200;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.progress-wrapper img{
    margin: auto;
}
