
.posts-page-wrapper{
    width: 100%;
    height: 96.5vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 0;
    /*font-family: "Cairo";*/
}

.posts-controls-wrapper{
    width: 100%;
    height: 15vh;
    display: flex;
    flex-direction: column;
    border-bottom: var(--border-strong) solid 2px;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--navbar-background);
}

.posts-buttons-wrapper{
    display: flex;
    width: 95%;
    justify-content: space-between;
}

.selected-posts-num{
    display: flex;
    color: var(--light-text-color);
    font-size: 1rem;
    font-weight: bold;
}
.selected-posts-num span{
    margin: auto;
}

.tools-bar-wrapper{
    display: flex;
    gap: 1rem;
    direction: rtl;
    align-items: center;
    justify-content: space-evenly;
    flex: 10;
    flex-grow: 0;
    padding-left: 1rem;
}

.tools-bar-wrapper label{
    font-size: 1rem;
    flex: 1;
    flex-grow: 0;
    min-width: 100px;
    flex-wrap: nowrap;
    text-align: center;
    color: var(--light-text-color);
}

.select-container{
    max-height: 100%;
    min-width: 200px;
    text-align: center;
    margin: auto;
    flex:2;
    flex-grow: 0;
}

.action-button{
    background-color: var(--primary-shade-1);
    min-width: 70px;
    padding: .3rem;
    border: none;
    border-radius: .2rem;
    color: var(--dark-text-color);
    flex:1;
    flex-grow: 0;
}

.action-button:hover{
    cursor: pointer;
    background-color: var(--button-color);
    color: var(--light-text-color);
}


.filter-action{
    display: flex;
    align-items: center;
    gap: .5rem;
    flex: 3;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.add-new-button{
    text-decoration: none;
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
    font-size: 1rem;
    font-weight: bold;
    border-radius:.3rem;
    border: none;
    padding: .2rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
}

.add-new-button:hover{
    background-color: var(--primary-color);
}
.add-new-button-wrapper{
    flex: 1;
    flex-grow: 0;
    min-width: 150px;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    display: flex;
}

.download-buttons-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.download-events-label{
    font-family: 'Cairo';
    color: var(--light-text-color);
}

.download-events-csv{
    background-color: var(--primary-shade-1);
    padding: .3rem;
    border: none;
    border-radius: .2rem;
}
.download-events-csv:hover{
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--light-text-color);
}

.download-events-json{
    background-color: var(--primary-shade-1);
    padding: .3rem;
    border: none;
    border-radius: .2rem;
}

.download-events-json:hover{
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--light-text-color);
}

.posts-table-wrapper{
    /*font-family: 'Cairo';*/
    text-align: left;
    width: 100%;
    height: 100%;
    margin-right: 0.5rem;
    overflow: auto;
}

.posts-table{
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    direction: rtl;
    border: var(--border-light) solid 2px;
    border-collapse: collapse;
    /*font-family: 'Cairo';*/
}

/*td:first-child {*/
/*    width: 5%;*/
/*}*/

/*td:nth-child(2) {*/
/*    width: 55%;*/
/*}*/
/*td:nth-child(3) {*/
/*    width: 20%;*/
/*}*/

/*td:last-child {*/
/*    width: 20%;*/
/*    background-color: var(--primary-shade-2-opacity);*/
/*}*/

.table-header-wrapper{
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
}

.table-header-item{
    text-align: center;
    background-color: var(--navbar-background);
    border-top: var(--border-strong) solid 2px;
    border-bottom: var(--border-strong) solid 2px;
    font-size:1rem;
    font-weight:bold;
}
.table-header-item label{
    color: var(--light-text-color);
    font-weight: bold;
    font-size: 1rem;
}

.table-header-body{
    overflow: auto;
    text-align: right;
    padding: 0.5rem;
}

.table-body-row{
    padding: 0.5rem;
}

.table-data{
    padding: 0.5rem;
    border-top: var(--border-strong) solid 2px;
    border-bottom: var(--border-strong) solid 2px;
    text-align: center;
}

.post-id{
    color: var(--dark-text-color);
    font-weight: bold;
    text-align: center;
}

.post-title-column{
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
}

.crud-row{
    display: flex;
    flex-grow: 0;
    justify-content: flex-start;
    align-items: center;
    max-height: 3rem;
}

.edit-post-btn{
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: transparent;
    border: none;
}

.edit-post-btn a{
    text-decoration: none;
    color: var(--light-text-color);
    background-color: dodgerblue;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: dodgerblue solid .15rem;
    border-radius: .5rem;
    padding: .2rem;
}

.edit-post-btn a:hover{
    cursor: pointer;
    color: dodgerblue;
    background-color: transparent;
}

.delete-post-btn{
    padding: 0.1rem 0.5rem;
    color: var(--light-text-color);
    font-size: 0.8rem;
    font-weight: bold;
    background-color: firebrick;
    border: firebrick solid .15rem;
    border-radius: .5rem;
}

.delete-post-btn:hover{
    cursor: pointer;
    color: firebrick;
    background-color: transparent;
}

.post-checkbox{
    padding: 0.5rem;
    border-top: var(--border-strong) solid 2px;
    border-bottom: var(--border-strong) solid 2px;
    text-align: center;
}

.post-title{
    font-weight: bold;
    color: var(--dark-text-color);
    text-align: right;
    margin-bottom: .5rem;
}

.post-lang{
    text-align: center;
}

.post-date{
    text-align: center;
}


@media screen and (max-width: 768px) {
    .posts-controls-wrapper{
        gap:.5rem;
        flex-direction: column-reverse;
        height: 35%;
    }
    .tools-bar-wrapper{
        gap: .5rem;
        align-items: stretch;
        padding-left: 0;
        flex-direction: column;
        flex:3;
    }

    .tools-bar-wrapper label{
        display: none;
    }


    .select-container{
        max-height: 80%;
        min-width: 150px;
    }

    .action-button{
        min-width: 70px;
        margin: auto;
        font-size: .9rem;
        flex-grow: 0;
    }
    .posts-table-wrapper{
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    .posts-table{
        border-collapse: collapse;
    }

    .table-header-item{
        padding: 0.5rem;
        margin: auto;
        text-align: center;
        border: var(--border-strong) solid 2px;
    }

    .table-header-body{
        overflow: auto;
        text-align: right;
        padding: 0.5rem;
        width: 100%;
    }

    .table-body-row{
        padding: 0.5rem;
    }

    .table-data{
        padding: 0.5rem;
        border: var(--border-strong) solid 2px;
        text-align: center;
    }

    .post-id{
        color: var(--dark-text-color);
        font-weight: bold;
        text-align: center;
    }

    .post-title-column{
        display: flex;
        flex-direction: column;
    }

    .crud-row{
        display: flex;
        flex-grow: 0;
        justify-content: flex-start;
        align-items: center;
        max-height: 3rem;
    }

    .edit-post-btn{
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
        padding: 0.5rem;
        color: blue;
        font-size: 0.8rem;
        font-weight: bold;
        background-color: transparent;
        border: none;
    }

    .edit-post-btn a{
        text-decoration: none;
        color: royalblue;
        justify-content: center;
        align-items: center;
        margin: auto;
        border: royalblue solid 1px;
        border-radius: .5rem;
            padding: .2rem;
    }

    .edit-post-btn a:hover{
        cursor: pointer;
        color: #f9ead7;

        background-color: royalblue;
    }

    .delete-post-btn{
        padding: 0.1rem 0.5rem;
        color: indianred;
        font-size: 0.8rem;
        font-weight: bold;
        background-color: transparent;
        border: indianred solid 1px;
        border-radius: .5rem;
    }

    .delete-post-btn:hover{
        cursor: pointer;
        color: #f9ead7;
        background-color: indianred;

    }

    .post-checkbox{
        text-align: center;
    }

    .post-title{
        font-weight: bold;
        color: var(--dark-text-color);
        text-align: right;
        margin-bottom: .5rem;
    }

    .post-lang{
        text-align: center;
    }

    .post-date{
        text-align: center;
    }
}


