:root{
    --primary-color:#4AAA57;
    --primary-shade-1: #85c48e;
    --primary-shade-2:#baddc0;
    --primary-shade-2-opacity:rgba(186, 221, 192,0.5);
    --primary-shade-2-opacity2:rgba(186, 221, 192,0.8);
    --secondary-color:#5AFFB8;
    --secondary-shade-1:#8dfecd;
    --secondary-shade-2:#befee3;
    --secondary-opacity:rgba(90, 255, 184,0.5);
    --box-shadow:rgba(20, 40, 29,.4);
    --background-color: #E6FFF8;
    --light-background:#edfef9;
    --lighter-background:#f5fefc;
    --navbar-background:#14281D;
    --dark-text-color:#14281D;
    --light-text-color:#E6FFF8;
    --border-strong:#65716b;
    --border-light:#aab0ad;
    --link-color:#4AAA57;
    --button-color:#4AAA57;
}