.login-wrapper{
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    background-color: var(--primary-shade-2-opacity);
}

.login-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form{
    width: 30%;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: #b3832f solid 2px;
    border-radius: .2rem;
    padding: 3rem;
    background-color: var(--navbar-background);
}

.login-form input{
    font-family: 'Cairo';
    color: #053742;
    margin: 0.5rem;
    background-color: #e8eff2;
    border-radius: .15rem;
}

.login-form-title{
    color: var(--light-text-color);
}

@media screen and (max-width: 768px) {
    .login-form{
        width: 100%;
        height: 100%;
    }

    .login-box{
        width: 90%;
        height: 90%;
    }
}
