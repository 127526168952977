.text-editor{
     background-color: white;
}

.react-quill{
    min-height: 300px;
    background-color: white;
    color: black;
    direction: rtl;
}

.react-quill div{
    font-size: 1rem;
    min-height: 300px;
    direction: rtl;
    text-align: right;
}

#toolbar{
    background-color: lightgray;
}

.ql-formats{
    background-color: white;
    border-radius: .5rem;
    padding: .2rem;
}



