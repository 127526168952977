.appearance-wrapper{
    direction: rtl;
    width: 99%;
    height: 100%;
    padding: 1rem;
    text-align: center;
}

.colors-palette{
    padding: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40%;
    text-align: right;
}

.colors-palette-title{
    flex-basis: 100%;
}

.color-unit{
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    border: var(--border-strong) .1rem solid;
    border-right: none;
    border-left: none;
    border-radius: .1rem;
}

.color-unit label{
    color: var(--dark-text-color);
    font-weight: bold;
}

.typography{
    display: flex;
    gap: 1rem;
    padding: 1rem;
}

.typography input{
    text-align: center;
}