.select-media-library-images-grid{
    margin-top: 1rem;
    max-height: 100vh;
    min-height: 30%;
    align-items: center;
    justify-content: center;
}
.select-media-thumbnail{
    width: 150px;
    height: 150px;
    border: var(--border-strong) 0.2rem solid;
    margin: .1rem;
}
.select-media-thumbnail-selected{
    width: 150px;
    height: 150px;
    margin: .1rem;
    border: var(--primary-color) .4rem solid;
}
.select-media-thumbnail:hover{
    cursor: pointer;
    margin: .2rem;
}

