.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem 0;
    padding: 0.5rem;
    animation: 1s fadeIn;
}

.sticky-year{
    position:sticky;
    top:0;
    left:10%;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.timeline-container::after {
    background-color: var(--primary-color);
    border: var(--box-shadow) .2rem solid;
    border-radius: .5rem;
    content: '';
    position: absolute;
    left: calc(50% - .7rem);
    width: 1rem;
    height: 100%;
}

.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}

.timeline-item-content {
    box-shadow: .2rem .2rem var(--box-shadow);
    border-radius: 5px;
    border: var(--primary-color) .2rem solid;
    background-color: var(--primary-shade-2-opacity);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 1rem;
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
    font-size: 0.8rem;
    color: var(--dark-text-color);
    direction: rtl;
}

.timeline-item-content::after {
    content: ' ';
    background-color: var(--primary-shade-1);
    border: var(--primary-color) .2rem solid;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -3%;
    top: calc(50% - .5rem);
    transform: rotate(45deg);
    width: .9rem;
    height: .9rem;
}

.timeline-item:nth-child(odd) .timeline-item-content {
    text-align: right;
}

.timeline-item:nth-child(odd) .timeline-item-content::after {
    right: auto;
    left: -3%;
}


.timeline-item:nth-child(odd) .timeline-item-content .tag {
    left: auto;
    right: 5px;
}

.timeline-item-content time {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: bold;
    direction: rtl;
    text-align: right;
}

.timeline-item-content h3 {
    font-size: 1rem;
}


.timeline-item-content .circle {
    background-color: var(--background-color);
    border: 3px solid var(--primary-shade-1);
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -10.5%;
    width: 1.25rem;
    height: 1.25rem;
    z-index: 100;
}

.timeline-item-content .date-test {
    color: var(--dark-text-color);
    border-radius: .3rem;
    border: var(--primary-color) solid .18rem;
    box-shadow: .15rem .15rem var(--box-shadow) ;
    background-color: var(--primary-shade-2-opacity);
    position: absolute;
    padding: .5rem 1.2rem;
    top: calc(50% - 24%);
    left: -55%;
    font-weight: bold;
    font-size: 1.3rem;
    z-index: 100;
}

.timeline-item:nth-child(even) .timeline-item-content .date-test {
    left: auto;
    right: -55%;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -11%;
}


.popup-content {
    width: 95%;
    max-width: 640px;
    padding: 1rem;
    direction: rtl;
    margin: auto;
    position: relative;
    font-size: 1rem;
}

.popup-arrow{
    color: var(--light-text-color);
    font-weight: bold;
    background-color: var(--button-color);
    border: .15rem solid var(--primary-shade-2-opacity);
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
    margin: auto;
}

.popup-arrow:hover{
    cursor: pointer;
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
    border-radius: 2rem;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.modal-post-content{
    padding: 1rem;
}

.modal-post-content a{
    color: var(--link-color);
    cursor: pointer;
    font-size: 1.2rem;
}

#modal-modal-title{
    padding: 2rem;
    font-size: 1rem;
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-modal-date{
    color: var(--primary-color);
}

@media screen and (max-width: 1440px) {
    .timeline-item-content::after {
        right: -3%;
        top: calc(50% - 1rem);
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -3%;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -3vw;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -3vw;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -15vw;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -15vw;
    }
}

@media screen and (max-width: 1280px) {
    .timeline-item-content::after {
        right: -3%;
        top: calc(50% - 1rem);
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -3%;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -3.35vw;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -3.35vw;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -17vw;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -17vw;
    }
}

@media screen and (max-width: 1024px) {
    .timeline-item-content::after {
        right: -3.5%;
        top: calc(50% - 1rem);
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -3.5%;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -4.2vw;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -4.2vw;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -22vw;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -21.5vw;
    }
}

@media screen and (max-width: 834px){


    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -5.1vw;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -5.1vw;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -27vw;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -26.5vw;
    }
}

@media screen and (max-width: 640px){
    .timeline-item-content::after {
        right: -3.5%;
        top: calc(50% - 1rem);
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -3.5%;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -4.2vw;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -4.2vw;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -22vw;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -21.5vw;
    }
}

@media screen and (max-width: 640px){



    #modal-modal-title{
        padding: .5rem;
        text-align: right;
        font-weight: bold;
        font-size: .8rem;
        justify-content: space-between;

    }

    .popup-content {
        width: 100vw;
    }

    .popup-overlay {
        /*margin: 5%;*/
        width:100vw;
        max-width: 460px;
    }

    [role='tooltip'].popup-content {
        margin: 5%;
        width: 100vw;
    }

    .timeline-item-content::after {
        right: -7.5%;
        top: calc(50% - 1rem);
    }

    .timeline-item:nth-child(odd) .timeline-item-content::after {
        right: auto;
        left: -7.5%;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    .timeline-item-content .circle {
        top: calc(50% - 1rem);
        right: -28.5%;
    }

    .timeline-item:nth-child(odd) .timeline-item-content .circle {
        right: auto;
        left: -28.5%;
    }

    .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: -140%;
    }

    .timeline-item:nth-child(even) .timeline-item-content .date-test {
        top: calc(50% - 35px);
        left: auto;
        right: -140%;
    }

}