.new-post-wrapper{
    width:100%;
    height: auto;
    min-height: 96vh;
    display: flex;
    flex-direction: row;
    direction: rtl;
    overflow: auto;
}

.new-post-page-title{
    text-align: center;
    font-size: 1.5rem;
}

.add-new-form{
    width: 90%;
    margin: auto;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.add-new-side-bar{
        flex-basis: 25%;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: var(--box-shadow) .1rem .1rem;
    background-color: var(--light-background);
}

.featured-image-file-browser{
    background-color: var(--box-shadow);
    height: 300px;
    width: 350px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .3rem;
}

.featured-image-file-browser label{
    background-color: var(--primary-shade-1);
    padding: .3rem .5rem;
    border-radius: .3rem;
}

.featured-image-file-browser label:hover{
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--light-text-color);
}

.select-media-button button{
    font-family: 'Cairo';
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
    font-weight: bold;
}

.add-new-box{
    color: var(--primary-color);
    flex-basis: 75%;
    overflow: auto;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem;
    animation: 1s fadeIn;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.select-wrapper{
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: auto;
    color: var(--dark-text-color);
    padding: 0.5rem;
}

.title-field{
    font-size: 1.2rem;
    width: 100%;
}

.edit-featured-image-field{
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-background);
    border:var(--border-light) .2rem solid;
}

.edit-featured-image-field button{
    margin: auto;
    min-width: 100px;
}

.edit-featured-image-field img{
    object-fit: contain;
    max-height: 100%;
}

.date-field{
    width: 25%;
}
.form-item{
    padding: 0.5rem;
    margin: 0.5rem;
}

.submit-button{
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.close-icon:hover{
    cursor: pointer;
}


.submit-button input{
    padding: .1rem .5rem;
    color: white;
    background-color:var(--button-color);
    font-size: 1rem;
    border:none;
    border-radius: .3rem;
    z-index: 5;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.submit-button input:hover{
    cursor: pointer;
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
}

#file-input{
    display: none;
}

@media screen and (max-width: 768px) {
    .select-wrapper {
        width: 100%;
    }

    .title-field {
        font-size: 1.2rem;
        width: 100%;
    }

    .date-field {
        width: 100%;
    }
}