.edit-post-wrapper{
    height: auto;
    min-height: 96vh;
    width:100%;
    margin: 0;
    direction: rtl;
    overflow: auto;
    background-color: var(--primary-shade-2-opacity);
    display: flex;
    flex-direction: row;
}

.edit-page-title{
    text-align: center;
    font-size: 1.5rem;
    color: var(--primary-color);
}

.edit-form{
    width: 100%;
    margin: auto;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1rem;
}

.edit-box{
    color: var(--dark-text-color);
    flex-basis: 75%;
    height: auto;
    overflow: auto;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    animation: 1s fadeIn;
    display: flex;
    flex-direction: column;
}

.edit-side-bar{
    flex-basis: 25%;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: var(--box-shadow) .1rem .1rem;
    background-color: var(--light-background);
}

.exit-to-dashboard-button{
    color: var(--dark-text-color);
    background-color: var(--primary-shade-2-opacity);
    border-radius: .5rem;
    border: none;
    flex-grow: 0;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    width: 42%;
    padding: .5rem;
    display: flex;
    gap: 1rem;
}

.exit-to-dashboard-button:hover{
    cursor: pointer;
    background-color: var(--primary-shade-2-opacity2);
}

.edit-excerpt-field{
    max-height: 100px;
    width: 100%;
}

.edit-excerpt-field-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:flex-start;
}

.edit-excerpt-field textarea{
    max-width: 100%;
    min-width: 100%;
    max-height: 150px;
    min-height: 150px;
}

.edit-featured-image-field{
    width: 100%;
    border: none;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-background);
}

.featured-image-crud-buttons{
    display: flex;
    gap: 1rem;
}
.edit-featured-image-field button{
    width: 80px;
    font-family: 'Cairo';
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
}

.edit-featured-image-field button:hover{
    background-color: var(--primary-color);
    color: var(--light-text-color);
}

.edit-featured-image-field img{
    object-fit: contain;
    max-width: 100%;

}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.edit-title-field{
    font-size: 1.2rem;
    width: 100%;
}

.edit-date-field-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.edit-date-field{
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
}

.edit-date-field input{
    width: 100%;
    padding: 0.5rem;
    margin: 0;
}

.custom-content-editor{
    padding-bottom: 1rem;
}
.custom-content-editor textarea{
    min-height: 40vh;
    max-width: 100%;
    min-width: 100%;
    font-size: 1.2rem;
}

.edit-form-item{
    padding: 0.5rem;
}

.submit-edit-button{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.close-icon:hover{
    cursor: pointer;
}

.submit-edit-button button{
    padding: .1rem .5rem;
    color: white;
    background-color:var(--button-color);
    font-size: 1rem;
    border:none;
    border-radius: .3rem;
    z-index: 5;
    box-shadow: -1px 1px 1px var(--border-strong);
}

.submit-edit-button button:hover{
    cursor: pointer;
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
}