.about-page-wrapper{
    width: 100%;
    height: 100%;
    padding: 0;
    direction: rtl;
    align-items: center;
    justify-content: center;
}

.content-wrapper{
     width: 100%;
    height: 100%;
    padding: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    animation: 1s fadeIn;
    align-items: center;
    justify-content: center;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.header-section{
    padding-top: 2rem;
    background-image: url("../../../assets/aboutUsDesktopBG.gif");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 80vh;
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header-title-wrapper{
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
}

.about-us-paragraph{
    font-size: 1.9vh;
    width: 70%;
    text-align: center;
    border-radius: 1rem;
}

.header-title{
    color: var(--primary-shade-1);
    font-size: 4rem;
}

.desc-section{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.desc-section div{
    flex-basis: 45%;
    padding: 2rem;
    text-align: center;
}

.desc-section h2{
    color: var(--primary-color);
    padding-top: 1rem;
    padding-bottom: 1rem;
}



@media screen and (max-width: 768px) {

    .header-section{
        background-image: url("../../../assets/aboutUsMobileBG.gif");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        height: 71vh;
    }

    .content-wrapper{
        width: 100%;
        height: 100%;
    }

    .header-title-wrapper{
        padding: .5rem;
    }

    .header-title{
        font-size: 3rem;
    }

    .about-us-paragraph{
        font-size: 1rem;
        width: 85%;
        text-align: justify;
    }

    .desc-section{
        gap: 0.2rem;
        text-align: justify;
        width: 100%;
    }

    .desc-section div{
        gap: 1rem;
        flex-basis: 90%;
        padding: .5rem;
        font-size: 1rem;
    }

    .site-content-desc{
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 1024px) {
    .about-us-paragraph{
        font-size: 1.7vh;
        width: 85%;
        text-align: center;
        border-radius: 1rem;
    }

    .desc-section{
        width: 95%;
    }
}
