.navbar-wrapper{
    width: 100%;
}

.navbar-wrapper-disabled{
    display: none;
}

.navbar{
    direction: rtl;
    position: relative;
    background-color: var(--navbar-background);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap:2rem;
    padding: 0 3rem;
    height: 10vh;
    width: 100%;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
    animation: fadeIn 1s;
}
.logo-link-wrapper-wrapper{
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: .2rem;
}
.logo-link-wrapper{
    margin: auto;
}

.logo-link-wrapper img{
    max-height: 100%;
    border-radius: 50%;
    margin: auto;
    width: auto;
}

.logo-link-wrapper img:hover{
    cursor: pointer;
    background-color: var(--primary-shade-2-opacity2);
    border: var(--light-text-color) .1rem solid;
}

.user-status-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex: 3;
    gap:0.5rem;
}
.signout-btn-wrapper:hover{
    cursor: pointer;
    border-radius: .5rem;
    background-color:RGB(250, 235, 215,0.1);
}

.status-email{
    font-size: 1rem;
}

.signout-btn:hover{
    cursor: pointer;
    background-color: RGB(229, 232, 239,0.2);
}

.navigation-menu{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-menu-list{
    list-style: none;
    display: flex;
    gap: 0.1rem;
    justify-content: center;
    align-items: center;
}

.navigation-menu a{
    color: var(--light-text-color);
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .5rem ;
    font-size: 1.3rem;
}

.navigation-menu li{
    border-radius: 0.5rem;
}

.navigation-menu li.active{
    background-color: RGB(76, 164, 66,0.1);
}

.navigation-menu li:hover{
    background-color:RGB(76, 164, 66,0.1);
}

.signal-btn{
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    display: none;
    color: var(--button-color);
}

.signal-btn:hover {
    font-size: 1.1rem;
}

.admin-navbar{
    flex: 9;
    display: flex;
    align-items: center;
}

.admin-navbar-list{
    display: flex;
    gap: .2rem;
    align-items: center;
    justify-content: flex-start;
    padding-right: 2rem;
}

.admin-navbar-list a{
    text-decoration: none;
    color: var(--light-text-color);
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.admin-navbar-list a:hover{
    border-radius: .5rem;
    background-color:RGB(250, 235, 215,0.1);
}

.above-header-wrapper{
    width: 100%;
    height: 3.5vh;
    border-top: var(--border-strong) solid 2px;
    border-bottom: var(--border-strong) solid 2px;
    background-color: var(--navbar-background);
    color: var(--light-text-color);
    font-size: 1.1rem;
    gap: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    direction: rtl;
    padding-left: 1rem;
    z-index: 1000;
}

.social-icons-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem;
    gap: 1rem;
}

.social-icons-wrapper a:hover{
    border-radius: .5rem;
    background-color:RGB(250, 235, 215,0.1);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media screen and (max-width: 768px) {

    .signal-btn{
        display: block;
        transition: 3s fade;
        padding: .2rem;
    }

    .signal-btn .open{
        display: none;
    }
    .signal-btn .closed{
        display: block;
    }

    .logo-link-wrapper-wrapper{
        height: 95%;
        max-width: 90%;
        max-width: 90%;
        align-items: center;
        justify-content: center;
    }
    .logo-link-wrapper{
        height: 100%;
    }
    .navbar{
        height: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap:2rem;
        padding: 0 .5rem;
    }

    .navigation-menu.expanded ul {
        display: block;
        z-index: 500;
        transition: all 5s cubic-bezier(0.16,1,0.3,1);
    }

    .navigation-menu ul {
        display: none;
        position: absolute;
        top: 9.5vh;
        right: 0;
        flex-direction: column;
        width: 60%;
        height: auto;
        background-color: var(--navbar-background);
        border-top: 1px solid var(--border-light);
    }

     .navigation-menu li {
         text-align: center;
         margin: 0;
     }

    .navigation-menu li a {
        padding: 1rem;
    }

    .admin-navbar{
        flex: 1;
    }

    .user-status-wrapper{
        flex: 1;
    }

    .admin-navbar-list{
        gap: 0rem;
        padding-right: 0;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    .admin-navbar-list a{
        font-size: .8rem;
        flex-shrink: 0;
        ;
    }

    .above-header-wrapper{
        width: 100vw;
        max-width: 100%;
        padding: 0;
        margin: 0;
        gap: .1rem;
        justify-content: center;
        align-items: center;
    }

    .signout-btn-wrapper{
        flex-shrink: 0;
        min-width: 70px;
        justify-content: center;
        align-items: center;
    }

    .signout-btn{
        font-size: 0.6rem;
        font-weight: bold;
        margin: auto;
        padding: 0.1rem;
        margin: .1rem;
    }
    .signout-btn:hover{
    cursor: pointer;
    background-color: RGB(229, 232, 239,0.2);
}


    .status-email{
        font-size: .8rem;
    }
}

