@import url('https://fonts.googleapis.com/css?family=Cairo:regular,bold,italic');
@import 'font-awesome/css/font-awesome.min.css';



* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Cairo";
}

.App {
    text-align: center;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-header{
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #053742;
    position: sticky;
    top: 0;
}

body {
  margin: 0;
  background-color: var(--primary-shade-2-opacity);
}

html, body{
    overflow-x: hidden;
}

.main{
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
}



@media screen and (max-width: 768px) {

    .App {
        width: 100vw;
    }

}