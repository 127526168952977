

.sidebar-container{
    position:fixed;
    right:0;
    top:3.5vh;
    height: 100%;
    width: 9%;
    background-color: var(--navbar-background);
    border-left: var(--border-strong);
    justify-content: left;
    text-align: left;
    direction: rtl;
    -webkit-transition: all 0.3s ease-in-out;
    z-index: 10;
}
.sidebar-container-closed{
    position: fixed;
    right:0;
    top:3.5vh;
    height: 100%;
    width: 4%;
    background-color: var(--navbar-background);
    border-left: var(--border-strong) solid 2px;
    justify-content: left;
    text-align: left;
    -webkit-transition: all 0.3s ease-in-out;
    z-index: 10;

}

.sidebar-closed{
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
}

.sidebar-header{
    background-image: url("../../assets/mobileBG.png");
    background-color: var(--border-strong);
    background-size:100% 100%;
    background-position: top;
    padding: 0;
    margin-bottom: 1rem;
    height: 15vh;
    border: var(--border-strong) solid 2px;
    -webkit-transition: all 0.3s ease-in-out;
}

.icon-wrapper{
    text-align: left;
    padding-left: 1rem;
    margin-top: 2rem;
}
.icon-wrapper-closed{
    text-align: center;
    margin-top: 2rem;
}

.icon-wrapper-closed:hover{
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}

.icon-wrapper:hover{
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}

@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(5px);
  }
}

.sidebar-item{
    display: flex;
    gap:1rem;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    color: var(--light-text-color);
}

.sidebar-item.active{
    background-color: var(--light-text-color);
    color:var(--dark-text-color);
}


.sidebar-item-icon{
    flex: 1;
    color: inherit;
    text-decoration: none;
    text-align: center;
}

.sidebar-item-label{
    flex: 2;
    color: inherit;
    text-align: right;
    -webkit-transition: all 0.3s ease-in-out;
}

a.sidebar-item-label{
    text-decoration: none;
    font-size: 1rem;
}

@media screen and (max-width: 768px) {
    .sidebar-container{
        width: 50vw;
    }
    .sidebar-container-closed{
        width: 10%;
    }

    .fa-icon{
        font-size:2rem;
    }

    .icon-wrapper{
         padding: 1rem;
        text-align: right;
    }
}