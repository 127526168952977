.media-library-wrapper{
    width: 100%;
    min-height: 100vh;
    direction: rtl;
}

.media-tools-bar{
    display: flex;
    flex-direction: row;
    padding: .5rem;
    justify-content: space-evenly;
    width: 100%;
    background-color: var(--navbar-background);
}

.media-bulk-delete{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
}

.media-bulk-delete button{
    font-family: 'Cairo';
    color: var(--dark-text-color);
    background-color: var(--primary-shade-1);
}

.media-bulk-delete button:hover{
    color: var(--light-text-color);
    background-color: var(--primary-color);
}

.add-new-media{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.add-new-media button{
    color: var(--dark-text-color);
    font-family: 'Cairo';
    background-color: var(--primary-shade-1);
}
.add-new-media button:hover{
    background-color: var(--primary-color);
    color: var(--light-text-color);
}

.media-library-images-grid{
    height: 100%;
    align-items: center;
    justify-content: center;
}

.media-library-upload-button{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.media-library-upload-button button{
    font-family: 'Cairo';
    background-color: var(--primary-shade-1);
    color: var(--dark-text-color);
}

.media-library-upload-button img{
    max-width: 100%;
}

.media-delete-button{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.media-delete-button button{
    font-family: 'Cairo';
    color: var(--light-text-color);
    background-color: firebrick;
}

.media-thumbnail{
    width: 150px;
    height: 150px;
    border: var(--border-strong) 0.2rem solid;
    margin: .1rem;
}

.media-thumbnail:hover{
    cursor: pointer;
    margin: .2rem;
}