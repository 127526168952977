.confirmation-modal-wrapper{
}

.confirmation-actions-bar{
    display: flex;
    width: 100%;
    height: auto;
    margin:auto;
    gap:1rem;
    align-items: center;
    justify-content: space-evenly;
}

.confirm-btn{
    background-color: indianred;
    color: white;
}

.confirm-btn:hover{
    cursor: pointer;
    background-color: palevioletred;
}

.cancel-btn{

}

.cancel-btn:hover{
    cursor: pointer;
}

.confirmation-btn{
    border: none;
    border-radius: .5rem;
    padding: .5rem;
    font-weight: bold;
}

.modal-wrapper{
    background-color: RGB(5, 55, 65,0.5);
    padding: 0;
    margin: 0;
}