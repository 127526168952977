.contact-section-wrapper{
    width: 100%;
    max-width: 100%;
    background-color: var(--navbar-background);
}

.contact-section{
    width: 50%;
    direction: rtl;
    display: flex;
    flex-direction: column;
    color: var(--light-text-color);
    padding: 3rem;
    margin: auto;
    text-align: center;
}

@media screen and (max-width: 1024px) {

    .contact-section{
        width: 90%;
        padding: 1rem;
    }
}

@media screen and (max-width: 768px) {

    .contact-section{
        width: 100%;
        padding: 1rem;
    }

}