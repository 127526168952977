

.management-page-wrapper{
    width:100%;
    height: 100%;
     -webkit-transition: all 0.3s ease-in-out;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
}

.management-page-wrapper-closed{
    width:96%;
    height: 100%;
     -webkit-transition: all 0.3s ease-in-out;
      padding: 0;
    display: block;
}

.layout-sidebar{
    position: fixed;
    top:3.5vh;
    right: 0;
    width: 10%;
    justify-content: stretch;
     -webkit-transition: all 0.3s ease-in-out;
    margin: 1rem;
}

.layout-sidebar-closed{
    width: 4%;
    justify-content: stretch;
     -webkit-transition: all 0.3s ease-in-out;
}

.layout-outlet{
    width: 90.9%;
    height: 100%;
    padding: 0;
    margin: 0;
     -webkit-transition: all 0.3s ease-in-out;
    align-items: stretch;
    justify-content: stretch;
}

.layout-outlet div{
    margin: 0;
    padding: 0;
}

.layout-outlet-closed{
    height: auto;
    padding: 0;
    margin: 0;
     -webkit-transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
    .management-page-wrapper-closed{
        width:93.5vw;
    }
    .management-page-wrapper{
        width:93.5vw;
    }
}