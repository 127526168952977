.users-page-wrapper{
    overflow: auto;
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 96.5vh;
}

.users-page-title{
    color: var(--primary-shade-1);
    margin: 1rem;
}

.users-table-wrapper{
    width: 100%;
    justify-content: center;
    align-items: center;
}

.users-tools-bar{
    padding: .5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--navbar-background);
    /*border-radius: .5rem .5rem 0 0;*/
}

.users-bulk-delete{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.users-bulk-delete button{
    font-family: 'Cairo';
    color: var(--dark-text-color);
    background-color: var(--primary-shade-1);
}

.users-bulk-delete button:hover{
    color: var(--light-text-color);
    background-color: var(--primary-color);
}

.add-new-user-button button{
    font-family: 'Cairo';
    color: var(--dark-text-color);
    background-color: var(--primary-shade-1);
}

.add-new-user-button button:hover{
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--light-text-color);

}
.users-bulk-select{
    min-width: 200px;
    font-family: 'Cairo';
    color: var(--dark-text-color);
    background-color: var(--primary-shade-1);
}

.add-new-user-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.add-new-user-form input{
    width: 90%;
    font-family: 'Cairo';
    color: var(--dark-text-color);
    /*background-color: var(--light-background);*/
    /*padding: .5rem 1rem;*/
}

.add-new-user-form button{
    color: var(--dark-text-color);
    padding: .5rem 1rem;
    background-color: var(--primary-shade-1);
    font-family: 'Cairo';
    font-weight: bold;
}


.edit-user-form{
    margin: auto;
    gap: 1rem;
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.edit-user-form input{
    padding: .5rem;
    width: 100%;
}