.landing-page-wrapper{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction:column ;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    background-image: url("../../../assets/WindturbinesCollage.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    animation: 1s fadeIn;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.landing-section{
    height: 55vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.website-desc-wrapper{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    background-color: var(--primary-shade-2-opacity);
    width: 100%;
    border-radius: 1rem;
    padding: 1rem;
}

.website-desc{
    color: var(--dark-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.website-title{
    font-size: 4rem;
    font-weight: bold;
    color: var(--dark-text-color);
}

.website-sub-title{
    font-size: 3rem;
    font-weight: lighter;
    color: var(--dark-text-color);
}

@media screen and (max-width: 768px) {

    .landing-page-wrapper{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-image: url("../../../assets/WindturbinesCollage.png");
        background-size: 210%;
        background-repeat: no-repeat;
        background-position: 0 0;
        align-items: center;
        justify-content: center;
        gap:4rem;
    }

    .website-desc-wrapper{
        width: 90%;
        height: 100%;
        margin-top: 2rem;
    }

  .website-desc{
    height: 100%;
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.website-title{
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--dark-text-color);
}

.website-sub-title{
    font-size: 1.5rem;
    font-weight: lighter;
    color: var(--dark-text-color);
}

.landing-section{
    height: 45vh;
    justify-content: center;
    align-items: center;
}

}