.statistics-page-wrapper{
    width: 100%;
    height: 96.5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    overflow: auto;
    animation: 2s fadeIn;
}

.statistics-page-title{
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
}

.dashboard-wrapper{
    width: auto;
    padding: 1rem;
    background-color: var(--primary-shade-2-opacity2);
    display: flex;
    gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    box-shadow: var(--box-shadow) .1rem .1rem;
    animation: 1s fadeIn;
    transition: ease-in;
}

.chart-wrapper{
    flex-basis: 35%;
    margin: auto;
}